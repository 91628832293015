<template>
    <div class="AboutUs">
        <!-- <bread-link></bread-link> -->
        <div class="banner1">
            <div class="title-box">
                <div class="ch-text">人力资源</div>
            </div>
        </div>
        <div class="warpper">
            <div class="content-box">
                <ul class="menu">
                    <li :class="nowType == item.value ? 'active' : ''" v-for="(item, index) in menuList" :key="index" @click="setDesc(item.value)">{{ item.name }}</li>
                </ul>
                <div class="main">
                    <pre v-if="nowType == 'Recruitment'" v-html="descInfo"></pre>
                    <!-- <div class="philosophy-box" v-if="nowType == 'Philosophy'">
                        <div class="menu-box">
                            <ul class="menu">
                                <li :class="actveIndex == index ? 'active' : ''" v-for="(item, index) in dataList" :key="index" @click="setDataListByIndex(index)">{{ item.type }}</li>
                            </ul>
                        </div>
                        <div class="list-box" v-if="activeData.length > 0">
                            <div class="list" v-for="(item, index) in activeData" :key="index">
                                <div class="title">{{ item.name }}</div>
                                <div class="pay">{{ item.salary }}</div>
                                <div class="desc-box">
                                    <span class="label">职位要求：</span>
                                    <pre class="info" v-html="item.positionRequire"></pre>
                                </div>
                            </div>
                        </div>
                        <a-spin class="loading" size="large" v-if="loading" />
                    </div> -->
                    <div class="groom-box" v-if="nowType == 'Philosophy'">
                        <div class="list-box">
                            <div class="list" v-for="(item, index) in dataList" :key="index" @click="readDetails('news',item)">
                                <div class="img-box">
                                    <img :src="item.img" width="300" height="225" alt="" />
                                </div>
                                <div class="title" :title="item.title">{{ common.splitStr(item.title, 30) }}</div>
                                <div class="date">{{ moment(item.publishDate || item.createTime).format('YYYY-MM-DD') }}</div>
                            </div>
                        </div>
                        <a-spin class="loading" size="large" v-if="loading" />
                        <div class="btn-box">
                            <span class="load-more" v-if="!loading && pageCount > dataList.length" @click="loadMore()">加载更多</span>
                        </div>
                    </div>
                    <div class="groom-box" v-if="nowType == 'Groom'">
                        <div class="list-box">
                            <div class="list" v-for="(item, index) in dataList" :key="index" @click="readDetails('news',item)">
                                <div class="img-box">
                                    <img :src="item.img" width="300" height="225" alt="" />
                                </div>
                                <div class="title" :title="item.title">{{ common.splitStr(item.title, 30) }}</div>
                                <div class="date">{{ moment(item.publishDate || item.createTime).format('YYYY-MM-DD') }}</div>
                            </div>
                        </div>
                        <a-spin class="loading" size="large" v-if="loading" />
                        <div class="btn-box">
                            <span class="load-more" v-if="!loading && pageCount > dataList.length" @click="loadMore()">加载更多</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
//页面引入swiper
import { getGwTalentConcept, getGwRecruitmentList, queryGwNewsPage } from '@/api/basic'
import moment from 'moment'
export default {
    name: 'Industry',
    data() {
        return {
            moment,
            loading: true,
            menuList: [
                { name: '人才理念', value: 'Recruitment' },
                { name: '人才招聘', value: 'Philosophy' },
                { name: '人才培训', value: 'Groom' }
            ],
            nowType: '',
            dataList: [],
            descInfo: '',
            searchParams: {
                type: 8,
                page: 1,
                rows: 20
            },
            pageCount: 0,
            actveIndex: 0,
            activeData: []
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            let type = this.$route.params.type;
            this.nowType = type ? type : 'Recruitment'
            if (this.nowType == 'Recruitment') {
                this.getRecruitmentData()
            } else if (this.nowType == 'Philosophy') {
                this.searchParams.type = 9;
                this.getNewsData()
            } else if (this.nowType == 'Groom') {
                this.searchParams.type = 8;
                this.getNewsData()
            }
        },
        // 获取人才理念
        getRecruitmentData() {
            getGwTalentConcept().then(res => {
                this.descInfo = res.data
            })
        },
        // 获取人才招聘
        getPhilosophyData() {
            this.loading = true
            getGwRecruitmentList().then(res => {
                this.dataList = res.data
                this.actveIndex = 0
                this.activeData = res.data[0].recruitmentList
                this.loading = false
            })
        },
        // 获取人才培训
        getNewsData() {
            this.searchParams.page = 1
            this.loading = true
            queryGwNewsPage(this.searchParams).then(res => {
                if (res.data.count != 0) {
                    this.dataList = res.data.data
                } else {
                    this.dataList = []
                }
                this.pageCount = res.data.count
                this.loading = false
            })
        },
        setDesc(type) {
            this.nowType = type
            this.dataList = []
            if (type == 'Recruitment') {
                this.getRecruitmentData()
            } else if (type == 'Philosophy') {
                this.searchParams.type = 9;
                this.getNewsData()
            } else if (type == 'Groom') {
                this.searchParams.type = 8;
                this.getNewsData()
            }
        },
        loadMore() {
            this.searchParams.page++
            queryGwNewsPage(this.searchParams).then(res => {
                this.dataList = this.dataList.concat(res.data.data)
                this.pageCount = res.data.count
                this.loading = false
            })
        },
        setDataListByIndex(index) {
            this.actveIndex = index
            this.activeData = this.dataList[index].recruitmentList
        },
        readDetails(type,item){
            if(item.url){
                window.open(item.url)
            }else{
                let id = item.id
                this.$router.push({ name: 'Details',params:{type:type,id:id} })
            }
        }
    }
}
</script>
<style lang="less" scoped>
.AboutUs {
    background: #f5f5f5;
    .banner1 {
        width: 100%;
        height: 680px;
        background: url(../../../assets/images/AboutUs/bg_1.png) 50% 50% no-repeat;
        position: relative;
        .title-box {
            width: 1300px;
            text-align: center;
            position: absolute;
            top: 20%;
            left: 50%;
            transform: translate(-50%, 50%);
            color: #fff;
            .en-text {
                font-size: 60px;
            }
            .ch-text {
                font-size: 50px;
                margin-top: 40px;
                position: relative;
                padding-bottom: 20px;
                position: relative;
                font-weight: bold;
            }
            .ch-text:before {
                content: '';
                width: 40px;
                height: 5px;
                background: #fff;
                position: absolute;
                bottom: 0;
                left: 50%;
                margin-left: -20px;
            }
        }
    }
    .warpper {
        width: 1300px;
        margin: 0 auto;
        margin-top: 100px;
        padding: 0 0 50px 0;
    }
    .content-box {
        display: flex;
        .menu {
            width: 200px;
            background: #fff;
            margin: 0;
            li {
                height: 55px;
                line-height: 55px;
                text-align: center;
                color: #333;
                box-sizing: border-box;
                cursor: pointer;
                border-top: 1px solid #e8e8e8;
                border-left: 1px solid #e8e8e8;
                border-right: 1px solid #e8e8e8;
            }
            li.active {
                background: #ff721d;
                color: #fff;
            }
            li:last-of-type {
                border-bottom: 1px solid #e8e8e8;
            }
        }
        .main {
            width: 1100px;
            padding: 30px 60px;
            background: #fff;
            pre {
                white-space: pre-wrap;
                word-break: break-all;
            }
            .groom-box {
                min-height: 200px;
                position: relative;
                .list-box {
                    display: flex;
                    flex-flow: row;
                    flex-wrap: wrap;
                    .list {
                        width: 300px;
                        cursor: pointer;
                        margin-right: 30px;
                        .img-box {
                            width: 300px;
                            overflow: hidden;
                            img {
                                transition: all 0.5s;
                            }
                        }
                        .title {
                            font-size: 18px;
                            font-weight: bold;
                            color: #333333;
                        }
                        .date{
                            margin-top: 20px;
                        }
                    }
                    .list:nth-of-type(3n) {
                        margin-right: 0;
                    }
                    .list:hover {
                        img {
                            transform: scale(1.3);
                        }
                        .title {
                            color: #ff721d;
                        }
                    }
                }
                .btn-box {
                    text-align: center;
                    margin-top: 30px;
                    .load-more {
                        display: inline-block;
                        margin-top: 40px;
                        color: #999999;
                        border: 1px solid #999999;
                        font-size: 14px;
                        padding: 5px 10px;
                        cursor: pointer;
                        border-radius: 3px;
                    }
                    .load-more:hover {
                        color: #ff721d;
                        border-color: #ff721d;
                    }
                }
                .loading {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
            .philosophy-box {
                min-height: 200px;
                position: relative;
                .menu-box {
                    display: flex;
                    .menu {
                        width: 100%;
                        display: flex;
                        flex-wrap: wrap;
                        li {
                            width: 100px;
                            height: 35px;
                            line-height: 35px;
                            margin-right: 10px;
                            margin-top: 10px;
                            text-align: center;
                            color: #333;
                            background: #f5f5f5;
                            box-sizing: border-box;
                            cursor: pointer;
                            border: 1px solid #e8e8e8;
                        }
                        li.active {
                            background: #ff721d;
                            color: #fff;
                        }
                    }
                }
                .list-box {
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    margin-top: 20px;
                    .list {
                        width: 470px;
                        background: #f5f5f5;
                        padding: 20px 30px;
                        .title {
                            font-size: 20px;
                            font-weight: bold;
                            color: #000000;
                            line-height: 44px;
                            border-bottom: 1px solid #d4d4d4;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            line-height: 44px;
                        }
                        .pay {
                            margin-top: 20px;
                            font-size: 20px;
                            font-weight: bold;
                            color: #ff721d;
                        }
                        .label {
                            padding: 10px 0;
                            display: block;
                            font-size: 16px;
                            color: #000000;
                        }
                        .info {
                            font-size: 16px;
                            color: #666666;
                        }
                        pre {
                            white-space: pre-wrap;
                            word-break: break-all;
                        }
                    }
                }
                .loading {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }
}
</style>
